@import './variables';
@import './mixins';
@import './component/app';
@import './component/login';
@import './component/header';
@import './component/notifications';
@import './component/userbox';
@import './component/layout';
@import './component/suspenseloader';
@import './component/sidebar';
@import './component/app';
@import './component/table';
@import './component/modal';
@import './component/bot_modal';

//common classes goes here
body {
  overflow-y: scroll !important;
  padding: 0 !important;
}

.cursorPointer {
  cursor: pointer;
}

.cursorDefault {
  cursor: default !important;
}

.hr-line {
  border: 1px solid $borderColor;
}

.p-absolute {
  position: absolute;
}

.p-relative {
  position: relative;
}

.p-fixed {
  position: fixed;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

//multiple file upload icon size

.text-center {
  text-align: center;
}

//Text Transform
.textUppercase {
  text-transform: uppercase !important;
}

.textCapitalize {
  text-transform: capitalize !important;
}

.textNone {
  text-transform: none !important;
}

//text align
.flex-basic-center {
  @include flex-basic-center;
}

.flex-column-center {
  @include flex-column-center;
}

.flex-basic-start {
  justify-content: start !important;
  @include flex-basic-center;
}

.flex-basic-space-between {
  justify-content: space-between !important;
  @include flex-basic-center;
}

.flex-basic-space-around {
  justify-content: space-around !important;
  @include flex-basic-center;
}

.flex-basic-end {
  justify-content: end !important;
  @include flex-basic-center;
}

.flex-direction-column {
  flex-direction: column;
}

.flex-column-end {
  display: flex;
  flex-direction: column !important;
  align-items: flex-end;
}

.flex-column-start {
  display: flex;
  flex-direction: column !important;
  align-items: center;
  justify-content: start;
}

.align-items-start {
  align-items: start !important;
}

.align-items-end {
  align-items: end !important;
}

.textDecorationNone {
  text-decoration: none !important;
}

.textweight {
  @include font-weight-bold;
}

.textalign {
  text-align: center;
}

//height
.h-100 {
  height: 100%;
}

//width per clasess
@for $i from 0 through 100 {
  .w-#{$i} {
    width: round(percentage($i/100));
  }
}

@for $i from 0 through 100 {
  .mt-#{$i} {
    margin-top: #{($i/16)}rem !important;
  }
}

@for $i from 0 through 100 {
  .mr-#{$i} {
    margin-right: #{($i/16)}rem !important;
  }
}

@for $i from 0 through 100 {
  .ml-#{$i} {
    margin-left: #{($i/16)}rem !important;
  }
}

@for $i from 0 through 100 {
  .mb-#{$i} {
    margin-bottom: #{($i/16)}rem !important;
  }
}

@for $i from 0 through 100 {
  .p-#{$i} {
    padding: #{($i/16)}rem !important;
  }
}

.d-flex {
  display: flex;
}

.m-width {
  min-width: 350 !important;
}

.sidebarwrapper {
  position: fixed;
  left: 0;
  top: 0;
}

.baselayout {
  flex: 1;
  height: 100%;

  &.admin-login {
    background-color: $adminLoginbackgroundColor;
  }
}

.selectformcontrol {
  width: 100%;
}

.overflow_auto {
  overflow: auto;
}

.flex_1 {
  flex: 1;
}

.overflow_x_hidden {
  overflow-x: hidden;
}

.alignitem {
  align-items: center;
}

.loginBox {
  position: relative;

  &::after {
    height: 100%;
    content: '';
    position: absolute;
    width: 1px;
    right: 0px;
    background-color: $borderBgColor;
  }
}

.commonListScroll {
  @include commonScrollBar;
  overflow: auto;
}

//password strength meter
.passStrengthWeak {
  background-color: $progressErrorBg !important;

  .MuiLinearProgress-bar1Determinate {
    background-color: $progressError !important;
  }
}

.passStrengthFair {
  background-color: $progressWarningBg !important;

  .MuiLinearProgress-bar1Determinate {
    background-color: $progressWarning !important;
  }
}

.passStrengthGood {
  background-color: $progressStrongBg !important;

  .MuiLinearProgress-bar1Determinate {
    background-color: $progressStrong !important;
  }
}

.passStrengthStrong {
  background-color: $progressSuccessBg !important;

  .MuiLinearProgress-bar1Determinate {
    background-color: $progressSuccess !important;
  }
}

.errorState {
  color: $errorTextColor !important;
}

.letter-spacing {
  letter-spacing: 0.5px;
}

.invoice_print_section {
  background-image: '../../assets/images/invoice/lado-ladi-invoice.png';
  background-size: a4;
}

/** 
Invoice start
*/
.invoice-box {
  max-width: 800px;
  margin: auto;
  padding: 30px;
  border: 1px solid #eee;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  font-size: 16px;
  line-height: 24px;
  font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
  color: #555;
}

.invoice-box table {
  width: 100%;
  line-height: inherit;
  text-align: left;
}

.invoice-box table td {
  padding: 5px;
  vertical-align: top;
}

.invoice-box table tr td:nth-child(2) {
  text-align: right;
}

.invoice-box table tr.top table td {
  padding-bottom: 20px;
}

.invoice-box table tr.top table td.title {
  font-size: 45px;
  line-height: 45px;
  color: #333;
}

.invoice-box table tr.information table td {
  padding-bottom: 40px;
}

.invoice-box table tr.heading td {
  background: #eee;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
}

.invoice-box table tr.details td {
  padding-bottom: 20px;
}

.invoice-box table tr.item td {
  border-bottom: 1px solid #eee;
}

.invoice-box table tr.item.last td {
  border-bottom: none;
}

.invoice-box table tr.total td:nth-child(2) {
  border-top: 2px solid #eee;
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  .invoice-box table tr.top table td {
    width: 100%;
    display: block;
    text-align: center;
  }

  .invoice-box table tr.information table td {
    width: 100%;
    display: block;
    text-align: center;
  }
}

/** RTL **/
.invoice-box.rtl {
  direction: rtl;
  font-family: Tahoma, 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
}

.invoice-box.rtl table {
  text-align: right;
}

.invoice-box.rtl table tr td:nth-child(2) {
  text-align: left;
}


/** 
Invoice end
*/

.print-box {
  display: none;
}

.a4-footer {
  position: absolute;
  bottom: 0px;
  padding: 10px;
}
