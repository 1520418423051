.source-table {
  border-radius: 10px;
}
.full-screen-bot-container {
  max-height: calc(100vh - 180px);
  height: calc(100vh - 180px);
  position: relative;
}

.bot-container {
  height: 100%;
}
.user-autocomplete-width {
  &.MuiAutocomplete-root {
    width: 100%;
  }
}
.bot-border-right {
  border-right: 1px solid $borderColor;
}
