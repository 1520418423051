.loginWrapper {
  .containerCenter {
    @extend .flex-basic-center;
    @extend .text-center;
    min-height: 100vh;
  }
  .loginWithGoogle {
    img {
      height: 25px;
      width: 25px;
    }
  }
}
