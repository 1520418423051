.tablestyle {
  height: auto;
  width: 100%;
  height: auto;
  &.MuiCard-root {
    border-radius: 10px !important;
  }
  .table {
    border: 0px;

    .MuiDataGrid-row,
    .MuiDataGrid-columnHeaders {
      .MuiDataGrid-columnHeaderTitle {
        align-items: center;
        @include font-weight-bold;
        text-transform: uppercase;
        // opacity: 0.7;
      }
    }
    .MuiDataGrid-cell {
      @include font-custom(400, 1rem, 1.5rem);
    }
    .MuiDataGrid-columnHeader:focus-within,
    .MuiDataGrid-cell:focus-within {
      outline: solid transparent 1px;
    }
    .table-header {
      border: 0px;
    }
    .MuiTablePagination-toolbar {
      margin-right: 4rem;
    }
  }
  .cell-h-76 {
    max-height: 76px !important;
    min-height: 76px !important;
  }
}

.table-page-dropdown {
  height: 36px !important;
  width: 74px !important;
  padding: 0px;
  background-color: $white !important;
  .expandMoreIcon {
    margin-left: -15px;
  }
}
.cell-padding {
  padding: 0px 40px 0px 40px;
}

.table-back-gound-green {
  background-color: rgb(32, 255, 106);
}

.table-back-gound-yellow {
  background-color: rgb(255, 247, 32);
}

.table-back-gound-gray {
  background-color: rgb(211, 211, 211);
}

.table-back-gound-red {
  background-color: rgb(241, 192, 192);
}

.table-back-gound-purple {
  background-color: rgb(102, 126, 234);
}

.table-back-gound-default {
  background-color: rgb(255, 255, 255);
}

.pagination-class {
  .MuiPaginationItem-root {
    &.Mui-selected {
      background-color: $pagination-selected-bd;
      color: $pagination-font;
    }
    &.Mui-selected:hover {
      background-color: $pagination-selected-bd;
      color: $pagination-font;
    }
  }
}

.width-search-input-box {
  min-width: 20rem;
}
