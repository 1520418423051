.delete-modal-headerIcon {
  height: 85px;
  width: 85px;
  border-radius: 100%;
  font-size: 36px;
  box-shadow: inset 0 1px 3px 0 #606190;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  svg {
    font-size: 35px;
  }
}

.color-delete-modal-subtitle {
  color: $delete-modal-subtitle;
}
